<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">


            <!-- Brand logo-->
            <b-link class="brand-logo" href="/register-pateint">
                <vuexy-logo />
                <h2 class="brand-text text-primary ml-1">
                    HEALTHPRO
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="6" class="d-none d-lg-flex align-items-center p-1">
                <div class="w-100 d-lg-flex align-items-top justify-content-center px-2">
                    <b-img fluid :src="imgUrl" alt="Register V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Register-->
            <b-col lg="6" class="d-flex align-items-top auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <!-- <StudentRegistation></StudentRegistation> -->
                    <b-row v-show="false" class="w-100" align-h="between">
                        <h3 class="ml-1" v-if="component != 'AccountSwitcher'" @click="switchComponent('AccountSwitcher')">
                            <i class="fa-solid fa-arrow-left"></i> <span style="margin-left: 5px;"> BACK</span>
                        </h3>
                    </b-row>

                    <b-row class="w-100 mt-3">
                        <component class="w-100" :is="component" @account="switchComponent" :outputlink="nextScreen">
                        </component>
                    </b-row>
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
    </div>
</template>
  
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormWizard from '@/views/forms/form-wizard/FormWizard.vue';
import AccountSwitcher from './AccountSwitcher.vue';
import StudentRegistration from './StudentRegistration.vue'
import CheckIfAccountExists from './CheckIfAccountExists.vue'
import HealthProRegistration from "./RegisterPro.vue"
import AddProffesionalLicense from './AddProffesionalLicense.vue'
import GoToDesk from './GoToDesk.vue';


export default {
    components: {
        VuexyLogo,
        BRow,
        BImg,
        BCol,
        BLink,
        BButton,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        // validations
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        AccountSwitcher,
        StudentRegistration,
        CheckIfAccountExists,
        HealthProRegistration,
        AddProffesionalLicense,
        GoToDesk,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            username: '',
            userEmail: '',
            password: '',
            firstname: '',
            lastname: '',
            component: "",
            sideImg: require('@/assets/images/pages/register-v2.svg'),

            // validation
            required,
            email,
            router: [],
            nextScreen: "StudentRegistration",
            isPro: true,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    async mounted() {
        let roles = await this.getRoles();
        roles = window.roles;
        if (roles.includes("Healthpro User")) {
            this.component = HealthProRegistration;
        } else if (roles.includes("Student")) {
            this.component = "StudentRegistration";
        }
        else {
            this.component = "GoToDesk"
        }
    },
    methods: {
        switchComponent(name) {
            this.component = name;
            this.router.push(name)
        },
        checkIsPro() {
            this.isPro = false;

        },
        validationForm() {
            this.$refs.registerForm.validate().then(success => {
                if (success) {
                    useJwt.register({
                        username: this.username,
                        email: this.userEmail,
                        first_name: this.firstname,
                        last_name: this.lastname,
                        password: this.password,
                    })
                        .then(response => {
                            localStorage.setItem('userData', JSON.stringify(response.data.message))
                            localStorage.setItem('reset_key', response.data.message.reset_password_key)
                            // this.$ability.update(response.data.userData.ability)
                            this.$router.push({ name: 'career-activate-account' })
                        })
                        .catch(error => {
                            // this.$refs.registerForm.setErrors(error.response.data.error)
                            /* eslint-disable no-console */
                            console.log(error.response.data.exception)
                            /* eslint-enable no-console */
                            // Invalid registration, ensure email is valid and passsword is 8 characters long
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Registration Error',
                                    icon: 'XOctagonIcon',
                                    variant: 'danger',
                                    text: 'Invalid registration, ensure email is valid and ensure password is 8 characters long and includes symbols, numbers and capital letters',
                                },
                            })
                        })
                }
            })
        },

    },
}
/* eslint-disable global-require */
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
  