<template>
  <b-container fluid class="mt-4">
    <b-row>
      <b-col>
        <h2>Healthcare Professional Portal</h2>
      </b-col>
      <div v-if="true"> <b-button variant="outline-dark" size="sm" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-button>
      </div>
    </b-row>

    <b-row v-if="isNew">


      <b-col cols="12">
        <b-form-group label="First Name:" label-for="first_name">
          <b-form-input id="first_name" v-model="proData.first_name" placeholder="Enter first name"
            required></b-form-input>
        </b-form-group>

        <b-form-group label="Last Name:" label-for="last_name">
          <b-form-input id="last_name" v-model="proData.last_name" placeholder="Enter last name" required></b-form-input>
        </b-form-group>

        <b-form-group label="Gender:" label-for="gender">
          <b-form-radio-group id="gender" v-model="proData.gender" required>
            <b-form-radio value="male">Male</b-form-radio>
            <b-form-radio value="female">Female</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label="Date of Birth:" label-for="date_of_birth">
          <b-form-datepicker id="date_of_birth" v-model="proData.date_of_birth" placeholder="Enter date of birth"
            required></b-form-datepicker>
        </b-form-group>

        <b-form-group label="Phone Number" label-for="last_name">
          <b-form-input id="last_name" v-model="proData.phone" placeholder="Enter phone number" required></b-form-input>
        </b-form-group>

        <b-form-group label="National ID:" label-for="last_name">
          <b-form-input id="last_name" v-model="proData.nid" placeholder="Enter national Id" required></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="Identification Type:" label-for="identificationType">
          <doctype-link :doctype="'Identification Type'" @selected="linkedSelected"></doctype-link>
        </b-form-group>

        <b-form-group label="Identification Number:" label-for="identificationNumber">
          <b-form-input v-model="identificationNumber"></b-form-input>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row v-else style="border-radius: 10px;border: 1px solid lightgray;" class="p-2 mt-1">
      <b-row class="w-100" align-h="between">

        <h4> {{ `${proData.full_name}` }}</h4>
        <div> <b-badge variant="danger"> <b> CPD POINTS:</b> {{ ` ${cpdCount || 0} ` }}</b-badge></div>
      </b-row>

      <b-row class="w-100" align-h="between">
        <span class="ml-0"> <b> DOB:</b> {{ proData.date_of_birth }} </span>
        <span class="ml-0"> <b> GENDER:</b> {{ proData.gender }} </span>
        <span class="ml-0 text-variant-success"> <b> HUID:</b> {{ proData.name }} </span>

      </b-row>
    </b-row>

    <b-row class="w-100 mt-2" v-if="cpdCount == 0" align-h="center" >
      <b-alert variant="warning" show> You currently do not have enough cpd points to apply of annual license</b-alert>
    </b-row>



    <b-row class="mt-1" v-if="!isNew">
      <b-col cols="12">
        <b-row align-h="between" style="margin-bottom: 5px;">
          <h5 style="font-weight: bold;">ANNUAL APPLIED LICENCES</h5>
          <AddProffesionalLicense  v-if="cpdCount > 0"  @result="saveApplication" :full_name="`${proData.full_name}`" :puid="proData.name"></AddProffesionalLicense>
        </b-row>
        <b-table small striped hover :items="registrations" :fields="registrationFields" show-detail-on-click
          head-variant="primary" variant="primary" bordered :show-empty="true"
          :empty-text="showEmptyMessage ? 'This table is empty' : 'Your pending approval License will be listed here'">
          <template #cell(docstatus)="row">
            <b-badge v-if="row.item.docstatus == 0" variant="dark" size="sm">Draft</b-badge>
            <b-badge v-else-if="row.item.docstatus == 1" variant="success" size="sm">Approved</b-badge>
            <b-badge v-else-if="row.item.docstatus == 2" variant="danger" size="sm">Rejected</b-badge>
          </template>

          <template #cell(licensing_body)="row">
            <b-row> <h5> {{ row.item.licensing_body }}</h5> </b-row>
            <b-row> {{ row.item.name }}</b-row>
          </template>

        </b-table>
      </b-col>
    </b-row>

    <b-row class="mt-3" v-if="!isNew">
      <b-col cols="12">
        <b-row align-h="between" style="margin-bottom: 5px;">
          <h5 style="font-weight: bold;">LICENCES</h5>
         
        </b-row>
        <b-table small striped hover :items="licenses" :fields="attachmentFields" show-detail-on-click
          head-variant="primary" variant="primary" bordered :show-empty="true"
          :empty-text="showEmptyMessage ? 'This table is empty' : 'Your License will be listed here'">
          <template #cell(actions)="row">
            <b-button variant="dark" size="sm" @click="print(row.item.name)">Print</b-button>
          </template>

          <template #cell(licensing_body)="row">
            <b-row> <h5> {{ row.item.licensing_body }}</h5> </b-row>
            <b-row> {{ row.item.name }}</b-row>
          </template>

        </b-table>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="!isNew">
      <b-col cols="12">
        <b-row align-h="between" style="margin-bottom: 5px;">
          <h5 style="font-weight: bold;">CPD EVENTS</h5>
          <!-- <b-button @click="showModal" size="sm" variant="dark">Add </b-button> -->
        </b-row>
        <b-table small striped hover :items="attandances" :fields="cpdFields" show-detail-on-click head-variant="primary"
          variant="primary" bordered :show-empty="true"
          :empty-text="showEmptyMessage ? 'This table is empty' : 'Upcoming Events will be listed here'">
          <template #cell(actions)="row">
            <b-button variant="primary" size="sm">View</b-button>
            <b-button variant="danger" size="sm">Remove</b-button>
          </template>
        </b-table>

      </b-col>
    </b-row>

    <b-row class="mt-1" v-if="false">
      <b-button variant="primary" size="sm" @click="saveData"> SAVE CHANGES</b-button>
    </b-row>

    <b-modal v-model="modalVisible" title="Add" hide-footer>
      <b-form @submit.prevent="addEvent">
        <b-form-group label="CPD Event" label-for="cpdEvent">
          <b-form-input id="cpdEvent" v-model="newEvent.cpd_event" required></b-form-input>
        </b-form-group>

        <b-form-group label="Event Description" label-for="eventDescription">
          <b-form-textarea id="eventDescription" v-model="newEvent.event_description" required></b-form-textarea>
        </b-form-group>

        <b-form-group label="Event Date" label-for="eventDate">
          <b-form-input id="eventDate" type="datetime-local" v-model="newEvent.event_date" required></b-form-input>
        </b-form-group>

        <b-form-group label="CPD Points" label-for="cpdPoints">
          <b-form-input id="cpdPoints" type="number" v-model="newEvent.cpd_points" required></b-form-input>
        </b-form-group>

        <b-form-group label="Doc Status" label-for="docStatus">
          <b-form-input id="docStatus" type="number" v-model="newEvent.docstatus" required></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Add Event</b-button>
      </b-form>
    </b-modal>


  </b-container>
</template>
  
<script>
import AddProffesionalLicense from './AddProffesionalLicense.vue';

export default {
  data() {
    return {

      cpdPoints: [],
      newEvent: {
        cpd_event: "",
        event_description: "",
        event_date: "",
        cpd_points: "",
        docstatus: "",
      },
      selectedInstitution: '',
      isNew: false,
      attachmentFields: ["licensing_body", "license_end", "actions" ],
      registrationFields: ["licensing_body",{key: "professional_cadre", label: "Type of Practice"} , {key: "docstatus", label: "Status"}, ],
      proData: {
        full_name: '',
        first_name: 'Demo',
        last_name: 'Practitioner',
        gender: 'male',
        date_of_birth: '',
        cpdPoints: 0,
        name: 'SUID-0001',
      },
      licenses: [], // Replace with fetched institution data
      attendances: [], // Manage attachment data here

      cpdCount: 0,
      
      cpdFields: [
        { key: "cpd_event", label: "CPD Event" },
        { key: "event_description", label: "Event Description" },
        { key: "event_date", label: "Event Date" },
        { key: "cpd_points", label: "CPD Points" },
        { key: "docstatus", label: "Status" },
      ],
      registrations: [],
    };
  },
  watch: {
    proData(proData) {

      if (proData && proData.name) {
        this.fetchLicenses(proData.name)
        this.getCpdPoints(proData.name)
        this.fetchRegistration(proData.name)
      }
    }
  },
  mounted() {
    this.fetchProProfile();
  },
  methods: {
    async saveApplication(doc){
      const result = await this.docSave(doc)
    },
    async getCpdPoints(puid) {
      const response = await this.api("healthpro.cpd_points_accrued", { hpuid: puid });
      const cpd = response.message;
      this.cpdCount = cpd.accrued_points;
      this.attendances = cpd.all_attendances;
    },
    print(name) {
      const w = window.open(this.get_full_url(`${'/printview?'
                + 'doctype='}${encodeURIComponent('Professional License Record')
      }&name=${encodeURIComponent(name)
      }&_lang=en&trigger_print=1`))
      if (!w) {
        this.msgprint(('Please enable pop-ups'))
      }
    },
    saveData() {
      this.isNew = false;
    },
    async fetchLicenses(puid) {
      const response = await this.docGetList("Professional License Record", ["name", "licensing_body", "license_end"], { professional_record_id: puid })

      this.licenses = response;
    },
    async fetchRegistration(puid) {
      const response = await this.docGetList("Professional License Registration", ["name", "licensing_body", "professional_cadre", "docstatus"], { professional_record_id: puid })

      this.registrations = response;
    },
    async fetchProProfile() {
      let studentList = await this.getDocPaginatedList("Professional Record", { user_id: this.session.user }, ["*"], 0, 1, "Dict", false);

      if (studentList.message != null && studentList.message.keys) {
        const current = studentList.message;
        this.contextStudent = this.convertToObject(current.keys, current.values);
        this.proData = this.contextStudent;

      } else {
        this.show_alert("Student Data Not found!")
      }

    },
    addAttachment() {
      // Handle attachment adding logic
    },
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    }
  },
  components: { AddProffesionalLicense }
};
</script>qq&