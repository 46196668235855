<template>
    <b-container class="mt-1">
        <b-form>
            <b-form-group label="Identification Type:" label-for="identificationType">
                <doctype-link :doctype="'Identification Type'" @selected="linkedSelected"></doctype-link>
            </b-form-group>

            <b-form-group label="Identification Number:" label-for="identificationNumber">
                <b-form-input v-model="identificationNumber"></b-form-input>
            </b-form-group>
≈
            <b-button @click="checkIdentification()" size="sm" type="submit" variant="primary">Check</b-button>
        </b-form>

        <div v-if="result !== null">
            <b-alert :variant="result ? 'success' : 'danger'">
                {{ result ? 'Identification number exists!' : 'Identification number does not exist.' }}
            </b-alert>
        </div>
    </b-container>
</template>
  
<script>
export default {
    props: {
        outputlink: String
    },
    data() {
        return {
            identificationType: null,
            identificationNumber: '',
            identificationTypeOptions: ['Type1', 'Type2', 'Type3'], // Add your identification types
            result: null,
        };
    },
    methods: {

        linkedSelected(doctype) {
           
        },
        async checkIdentification() {
            this.$emit('account', this.outputlink)
            return;
            try {
                // Make an Axios request to your API endpoint with identificationType and identificationNumber
                this.$emit('account', "StudentRegistration")
                //   const response = await this.$axios.post('/api/check-identification', {
                //     identificationType: this.identificationType,
                //     identificationNumber: this.identificationNumber,
                //   });

                // Update the result based on the response
                this.result = response.data.exists;
            } catch (error) {
                console.error('Error checking identification:', error);
                // Handle error if needed
            }
        },
    },
};
</script>
  
<style scoped>
/* Add custom styles if needed */
</style>
  