import { render, staticRenderFns } from "./RegisterPro.vue?vue&type=template&id=2a734e58&"
import script from "./RegisterPro.vue?vue&type=script&lang=js&"
export * from "./RegisterPro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports