<template>
    <b-container fluid class="p-1">
    <h2 class="text-center mb-4">Select Profile </h2>
      <b-row class="justify-content-center w-100">
        <b-col cols="12" class="mb-r">
          <b-card title="Student/Trainee" class="card-height" @click="handleCardClick('CheckIfAccountExists')">
            <!-- <p>Sign up as student here</p> -->
          </b-card>
        </b-col>
        <b-col cols="12"  class="mb-r mx-1">
          <b-card title="Health Professional" class="card-height" @click="handleCardClick('HealthProRegistration')">
            <!-- <p>Sign in as a health proffesional.</p> -->
          </b-card>
        </b-col>
     
      </b-row>
    </b-container>
  </template>
  
  <script>
 
  
  export default {
    components: {

    },
    methods: {
      handleCardClick(cardTitle) {
        this.$emit('account', cardTitle)   
      },
    },
  };
  </script>
  <style scoped>

.card-height {
    height: 8vh !important;
    border: 1px solid lightgray;
    padding-bottom: 20px;
}
</style>