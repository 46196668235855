<template>
  <div>
    <!-- Button to trigger the modal -->
    <b-button size="sm" variant="dark" @click="showModal">Apply</b-button>

    <!-- Modal component -->
    <b-modal v-model="modalVisible" title="Apply for Annual License" hide-footer class="pb-2">
      <!-- Form fields go here -->
      <b-form-group label="Licensing Body" v-bind="getFieldAttributes('licensing_body')">
        <doctype-link doctype="Company" @selected="(value) => formData.licensing_body = value"
          :options="licensingBodyOptions" required />
      </b-form-group>

      <!-- <b-form-group label="Professional Record ID" v-bind="getFieldAttributes('professional_record_id')">
        <doctype-link :placeholder="puid" doctype="Professional Record"
          @selected="(value) => formData.professional_record_id = value" required />

      </b-form-group> -->

      <b-form-group label="Type of Practice" v-bind="getFieldAttributes('professional_cadre')">
        <doctype-link doctype="Designation" @selected="(value) => formData.professional_cadre = value" required />
      </b-form-group>

      <!-- Save and Close buttons -->
      <b-button @click="saveAndClose" variant="dark" size="sm" class="mr-1">Save</b-button>
      <b-button @click="closeModal" size="sm" variant="outline-dark">Close</b-button>
    </b-modal>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      modalVisible: false,
     
     formData: {
        doctype: "Professional License Registration",
        licensing_body: null,
        full_name: null,
        professional_cadre: null,
        license_prefix: "PUID-",
        is_active: true,
       
      
      },
      licensingBodyOptions: [], // Populate with data
      professionalRecordOptions: [], // Populate with data
      professionalCadreOptions: [], // Populate with data
      amendedFromOptions: [] // Populate with data
    };

  },
  props: {
    full_name: String, puid: String
  },
  mounted() {
    this.formData.full_name = this.full_name;
    this.formData.professional_record_id = this.puid
  },
  methods: {


    showModal() {
      // Add logic to populate dropdown options if needed
      this.modalVisible = true;
    },
    saveAndClose() {
      // Add logic to save form data
      this.modalVisible = false;
     const payload ={
        doc: { "docstatus": 0, "doctype": "Professional License Registration", "name": "new-professional-license-registration-ipwtrgzvjm", "__islocal": 1, "__unsaved": 1, "owner": this.session.user, "is_active": 1, "full_name": "Dr JOSHUA  MUTEI", "professional_cadre": "Analyst", "passport_photo": null, "professional_record_id": this.puid, "license_prefix": "PUID-" },
        
      }
      const resultObject = {...payload.doc, ...this.formData}
     this.$emit("result", resultObject )
    },
    closeModal() {
      this.modalVisible = false;
    },
    getFieldAttributes(fieldName) {
      // Add logic to set attributes based on the DocType structure
      return {}; // Return attributes for the specified field
    }
  }
};
</script>
  
<style>
/* Add custom styles if needed */
</style>
  