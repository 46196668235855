<template>
    <b-container style="width: 50px;" >
        
        <b-button variant="dark" size="sm" @click="showModal()"> Add</b-button>

        <b-modal :id="`Velo@2020`" title="Add Attachment" @ok="handleOk(file)">
            <b-form-group label="File Name:" label-for="year">
                <b-form-input v-model="title"></b-form-input>
            </b-form-group>

            <b-form-group label="File" label-for="attachment">
                <b-form-file  v-model="file" accept=".jpg, .png, .gif, .pdf" placeholder="Attach" />
            </b-form-group>
        </b-modal>
    </b-container>
</template>
  
<script>

import useJwt from '@/auth/jwt/useJwt'

export default {
    components: {
       
    },
   
    props: { userData: {}, doctype: String, docname: String, lineId: String },
    data() {
        return {
            nextTodoId: 1,
            title,
        }
    },
    mounted() {
        if (!this.userData.custom_attachments) {
            this.userData.custom_attachments = [{ file_name: '', attachment: '' }]
        }

        if (this.userData.custom_attachments && this.userData.custom_attachments.length) {
            return
        }

        this.initTrHeight()
    },
    created() {
        if (!this.userData.custom_attachments) {
            this.userData.custom_attachments = [{ file_name: '', attachment: '' }]
        }
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    methods: {
        uploadFile(file) {


            useJwt.uploadFile({ file }, { doctype: this.doctype, docname: this.docname }).then(response => {
                this.$emit("file", {file_name: this.title, attachment: response.data.message.file_url})
            }).catch(error => alert(JSON.stringify(error)))
        },
        handleOk(file) {

            this.uploadFile(file)


        },
        showModal(id) {
            this.$bvModal.show('Velo@2020');
        },
        repeateAgain() {
            this.userData.custom_attachments.push({
                id: this.nextTodoId += this.nextTodoId,
            })
            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index) {
            this.userData.custom_attachments.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
    },
}
</script>
  
<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>
  