<template>
    <b-container fluid class="mt-2">
        <b-row align-h="between" v-f="false">
            <h3>{{ isNew ? 'Student Registration' : "Student/Trainee Portal" }}</h3>
            <div v-if="true"> <b-button variant="outline-dark" size="sm" @click="logout">
                    <b-row> <feather-icon size="16" icon="LogOutIcon" class="mr-50" /> Logout</b-row>
                </b-button>
            </div>
        </b-row>


        <b-row v-if="isNew">
            <b-col cols="12">
                <b-form-group label="First Name:" label-for="first_name">
                    <b-form-input id="first_name" v-model="proData.first_name" placeholder="Enter first name"
                        required></b-form-input>
                </b-form-group>

                <b-form-group label="Last Name:" label-for="last_name">
                    <b-form-input id="last_name" v-model="proData.last_name" placeholder="Enter last name"
                        required></b-form-input>
                </b-form-group>

                <b-form-group label="Gender:" label-for="gender">
                    <b-form-radio-group id="gender" v-model="proData.gender" required>
                        <b-form-radio value="male">Male</b-form-radio>
                        <b-form-radio value="female">Female</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

                <b-form-group label="Date of Birth:" label-for="date_of_birth">
                    <b-form-datepicker id="date_of_birth" v-model="proData.date_of_birth" placeholder="Enter date of birth"
                        required></b-form-datepicker>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Identification Type:" label-for="identificationType">
                    <doctype-link :doctype="'Identification Type'"
                        @selected="val => proData.identifier_type = val"></doctype-link>
                </b-form-group>

                <b-form-group label="Identification Number:" label-for="identificationNumber">
                    <b-form-input v-model="proData.identifier_number"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="12" class="">
                <b-form-group label="Academic Institution:" label-for="institution">
                    <doctype-link :doctype="'Company'" @selected="companySelected"></doctype-link>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="12">
                <b-form-group label="Academic Year:" label-for="year">
                    <doctype-link :doctype="'Academic Year'" @selected="yearSelected"></doctype-link>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-form-group label="Identification Type:" label-for="identificationType">
                    <doctype-link :doctype="'Identification Type'"
                        @selected="val => proData.identifier_type = val"></doctype-link>
                </b-form-group>

                <b-form-group label="Identification Number:" label-for="identificationNumber">
                    <b-form-input v-model="proData.identification_number"></b-form-input>
                </b-form-group>
            </b-col>

        </b-row>

        <b-row v-else style="border-radius: 10px;border: 1px solid lightgray;" class="p-2 mt-1">

            <hr />
            <b-row class="w-100" align-h="between">

                <h4> {{ `${proData.first_name} ${proData.last_name}` }}</h4>
                <div> <b-badge variant="dark"> {{ proData.name }}</b-badge></div>

            </b-row>

            <b-row class="w-100">

                <span class=""> <b> DOB:</b> {{ proData.date_of_birth }} </span>
                <span class="ml-2 text-variant-success"> <b> PHONE:</b> {{ proData.student_mobile_number }} </span>

            </b-row>

            <b-row class="mt-1">
                <h5 style="color:gray"> {{ `INSTITUION: ${this.proData.custom_academic_institution}`.toUpperCase() }} </h5>
            </b-row>
        </b-row>

        <b-row class="mt-3" v-if="!isNew">
            <b-col cols="12">
                <b-row align-h="between" style="margin-bottom: 5px;">
                    <h5 style="font-weight: bold;">ATTACHMENTS</h5>
                    <div>
                        <FileUploaderTable @file="addFile" style="margin-right: 20px;" doctype="Student"
                            :docname="proData.name" :userData="{ custom_attachments: [{}] }"></FileUploaderTable>
                    </div>
                </b-row>

                <b-row>


                </b-row>
                <b-table small striped hover :items="attachments" :fields="cpdFields" show-detail-on-click
                    head-variant="primary" variant="primary" bordered :show-empty="true"
                    :empty-text="showEmptyMessage ? 'This table is empty' : 'Add all your academic certificates here'">
                    <template #cell(actions)="row">
                        <b-button variant="primary" size="sm">View</b-button>
                        <b-button variant="danger" size="sm">Remove</b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>


        <b-row class="mt-1">

        </b-row>

    </b-container>
</template>
    
<script>
import FileUploaderTable from '../erp/components/FileUploaderTable.vue';

export default {
    components: {
        FileUploaderTable,
    },
    data() {
        return {
            selectedInstitution: '',
            isNew: false,
            selectedCompany: '',
            selectedYear: '',
            proData: {
                first_name: '',
                last_name: '',
                gender: '',
                date_of_birth: '',
                cpdPoints: 0,
                name: 'SUID-0001',
                custom_academic_institution: '',
                year: '',
                student_mobile_number: '',
            },
            institutions: [], // Replace with fetched institution data
            attachments: [], // Manage attachment data here
            cpdFields: [
                { key: "file_name", label: "File Name" },
                { key: "attachment", label: "File" },

            ],
            contextStudent: null
        };
    },
    mounted() {
        // Fetch institutions data here
        this.fetchStudentProfile()
    },
    methods: {

        async fetchAttachments(parent) {
            const response = await this.api("healthpro.api.file.upload.get_attachments", { parent })
            this.attachments = response.message;
        },
        async fetchStudentProfile() {
            let studentList = await this.getDocPaginatedList("Student", { user: this.session.user }, ["*"], 0, 1, "Dict", false);

            console.log(studentList.message)
            if (studentList.message != null && studentList.message.keys) {
                const current = studentList.message;
                this.contextStudent = this.convertToObject(current.keys, current.values);
                this.proData = this.contextStudent;
                const result = await this.fetchAttachments(this.proData.name)

            } else {
                this.show_alert("Student Data Not found!")
            }

        },
        saveData() {
            this.isNew = false;
        },
        addAttachment() {
            // Handle attachment adding logic
        },
        companySelected(company) {
            this.selectedCompany = company;
            this.proData.custom_academic_institution = company;
        },
        async addFile(data) {
            this.attachments.push(data)
            let doc = this.proData;
            doc.doctype = "Student";
            doc.custom_attachments = this.attachments;
            const result = await this.docSave(doc)
            this.show_alert("Uploaded")
        },
        yearSelected(year) {
            this.selectedYear = year;
            this.prodData.year = year;

        },
        logout() {

            // Remove userData from localStorage
            localStorage.removeItem('userData')
            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
        }
    },
};
</script>
<style scoped>
svg {
    max-width: 20px !important;
}
</style>